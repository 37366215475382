<template>
  <div class="order">
    <!-- 订单 -->
    <left-tabs :tabList="leftList" />
    <router-view></router-view>
  </div>
</template>
<script>
import LeftTabs from "@/components/leftTabs";
import echarts from "echarts";
export default {
  components: {
    LeftTabs,
  },
  data() {
    return {
      leftList: [
        { id: 1, name: "订单列表", url: "/order/list" },
        { id: 2, name: "售后订单", url: "/order/after" },
        { id: 3, name: "预警订单", url: "/order/warning" }
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="less">
.order {
  display: flex;
}
</style>